@charset "UTF-8";
@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?9erxip");
  src: url("fonts/icomoon.eot?9erxip#iefix") format("embedded-opentype"), url("fonts/icomoon.ttf?9erxip") format("truetype"), url("fonts/icomoon.woff?9erxip") format("woff"), url("fonts/icomoon.svg?9erxip#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-menu:before {
  content: ""; }

.icon-cross:before {
  content: ""; }

.icon-arrow-left2:before {
  content: ""; }

.icon-mail2:before {
  content: ""; }

.icon-instagram:before {
  content: ""; }

.icon-twitter:before {
  content: ""; }

.icon-steam2:before {
  content: ""; }

.icon-github:before {
  content: ""; }

.icon-linkedin:before {
  content: ""; }

.icon-codepen:before {
  content: ""; }

a[class*=icon],
a[class*=icon]:visited {
  display: inline-block;
  color: #333;
  font-size: 1.5em;
  text-decoration: none;
  transition: transform .4s ease; }
  a[class*=icon]:hover,
  a[class*=icon]:visited:hover {
    transform: scale(1.2);
    color: #ff4c4c; }
