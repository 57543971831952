.menu {
	background-color: #ddd;
	box-shadow: 5px 0px 10px 0px rgba(0,0,0,0.5);
	box-sizing: border-box;
	min-height: 100vh;
	padding-left: 50px;
	padding-top: 20px;
	position: fixed;
	left: 0;
	transition: transform .4s ease-out;
	top: 0;
	width: 15%;
	transform: translateX(-105%);
	z-index: 2;
	
	@media (max-width: 920px) {
		align-items: flex-end;
		display: flex;
		justify-content: flex-end;
		padding-right: 20px;
		padding-bottom: 190px;
		right: 0;
		left: initial;
		transform: translateX(105%);
		width: 65%;
	}
	
	&.active {
		transform: translateX(0);
	
		.menu__item {
			animation: item-animation 1s ease-out;

			@media (max-width: 920px) {
				text-align: right;
			}

			@keyframes item-animation {
				from {
					opacity: 0;
					transform: translateX(-60px) skewY(20deg);
				}

				to {
					opacity: 1;
					transform: translateX(0) skewY(0);
				}
			}
		}
	}

	.logo {
		transform: translateX(-120%);
		transition: transform .6s ease-out;
		margin-bottom: 40px;
		list-style: none;
		font-weight: bold;
		font-size: 1.15em;
		
		&.show { transform: translateX(0); }
	
		@media (max-width: 920px) { display: none; }
	}

	.icon-close-menu {
		bottom: 50px;
		cursor: pointer;
		left: 50px;
		position: fixed;

		@media (max-width: 920px) {
			bottom: 140px;
			right: 23px;
			text-align: right;
		}
	}

	&__item {
		font-size: 1.5em;
		list-style: none;
		transition: transform .4s ease;
		
		a {
			text-decoration: none;

			
			&:hover {
				text-decoration: underline;
				text-decoration-color: #ff4c4c;
			}
		}
	}
}