.shelf {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 10px 10px;

    @media (max-width:  920px) {
        justify-content: center;
    }

    &__title {
        width: 100%;
        text-align: left;
        margin-left: 25px;
        font-size: 2em;
    }
}