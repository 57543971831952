.not-found {
	align-items: center;
	display: flex;
	justify-content: center;
	height: 100vh;
	text-align: center;

	&__highlight {
		font-size: 5.5em;
		line-height: 60px;
	}

	&__text {
		font-size: 1.5em;
	}
}