$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-menu: "\e9bd";
$icon-cross: "\ea0f";
$icon-arrow-left2: "\ea40";
$icon-mail2: "\ea84";
$icon-instagram: "\ea92";
$icon-twitter: "\ea96";
$icon-steam2: "\eaad";
$icon-github: "\eab0";
$icon-linkedin: "\eac9";
$icon-codepen: "\eae8";





@font-face {
	font-family: '#{$icomoon-font-family}';
	src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?9erxip');
	src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?9erxip#iefix') format('embedded-opentype'),
		url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?9erxip') format('truetype'),
		url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?9erxip') format('woff'),
		url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?9erxip##{$icomoon-font-family}') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: '#{$icomoon-font-family}' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-cross {
  &:before {
    content: $icon-cross; 
  }
}
.icon-arrow-left2 {
  &:before {
    content: $icon-arrow-left2; 
  }
}
.icon-mail2 {
  &:before {
    content: $icon-mail2; 
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram; 
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter; 
  }
}
.icon-steam2 {
  &:before {
    content: $icon-steam2; 
  }
}
.icon-github {
  &:before {
    content: $icon-github; 
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin; 
  }
}
.icon-codepen {
  &:before {
    content: $icon-codepen; 
  }
}

a[class*=icon],
a[class*=icon]:visited {
	display: inline-block;
	color: #333;
	font-size: 1.5em;
	text-decoration: none;
	transition: transform .4s ease;
	
	&:hover {
		transform: scale(1.2);
		color: #ff4c4c;
	}
}
