* {
	color: #333;
	font-family: 'Open Sans Condensed', sans-serif;
	margin: 0;
	padding: 0;

	&::selection {
		background-color: #ff4c4c;
	}
}

html,
body {
	background-color: #eee;
	height: 100vh;
	width: 100%;
	
}

body {
	display: flex;
	justify-content: center;
	align-items: center;
}

.content {
	display: inline-block;
	overflow: hidden;
}

.icon-menu {
	bottom: 50px;
	cursor: pointer;
	position: fixed;
	left: 50px;
	
	@media (max-width: 920px) {
		bottom: 20px;
		right: 20px;
		text-align: right;
	}
}