.about {
	.content {
		display: grid;
		grid-template-columns: 1fr 2fr 1fr;
		margin-top: 10vh;

		&::after {
			content: '';
			display: block;
		}

		@media (max-width: 920px) {
			grid-template-columns: 1fr;
		}
		
		.resume {
			align-items: center;
			flex-direction: column;
			display: flex;
			text-align: center;
			justify-content: center;
			
			@media (max-width: 920px) {
				align-items: flex-start;
				justify-content: space-around;
				flex-direction: row;
			}
			
			.profile-picture {
				width: 150px;
				height: 150px;
				overflow: hidden;
				border-radius: 100%;

				img {
					transform: translate(-30px, 0px);
					width: 160%;
					height: auto;
				}
			}

			&__capacities {
				list-style: none;
				text-align: left;
				margin-top: 45px;

				@media (max-width: 920px) {
					margin-top: 20px;
				}
			}
		}

		.history {
			@media (max-width: 920px) {
				margin: 40px auto 60px;
			}

			p {
				font-size: 1.25em;
				margin-bottom: 10px;
				text-align: justify;

				@media (max-width: 920px) {
					font-size: 1em;
					margin: 5px auto;
					width: 85%;
				}

				&::before {
					content: '';
					display: inline;
					margin-left: 15px;
				}
			}
		}
	}
}