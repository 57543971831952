section.presentation {
	display: flex;
	flex-direction: column;

	p { font-size: 1.25em; }

	h1 {
		font-size: 3em;
		font-weight: 700;
		line-height: 32px;

		span { font-weight: 300; }

		@media (max-width: 920px) {
			font-size: 2em;
		}
	}

	h2 {
		font-weight: 300;
		font-size: 1.75em;
		text-align: left;

		span {
			font-size: 1.25em;
		}
	}
}